let restaurants = [];

restaurants.push({
  name: "Maida Vale",
  openingTimes: {
    monday: "08:00 - 23:00",
    tuesday: "08:00 - 23:00",
    wednesday: "08:00 - 23:00",
    thursday: "08:00 - 23:00",
    friday: "08:00 - 23:00",
    saturday: "08:00 - 23:00",
    sunday: "08:00 - 23:00",
  },
  address: "65 Fernhead Rd, London W9 3EY",
  phone: "020 8964 2011",
  deliveroo: "https://deliveroo.co.uk/menu/london/kensal-town/the-cedar",
  uberEats:
    "https://www.ubereats.com/gb/store/the-cedar-lebanese-maida-vale/oLoVbFqzTTehlWGlftDfgg",
  path: "/menus/Maida-Vale-Menu.pdf",
  menu: {
    Terwika: [
      {
        name: "Shakshouka",
        ingredients: "eggs, tomato and onion",
        price: 6.3,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Mousabaha",
        ingredients: "chick peas in olive oil, lemon juice and tahiti sause",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Foul Modammas",
        ingredients:
          "tenderly cooked brood beans served in olive oil and lemon juice",
        price: 7.2,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Balilah",
        ingredients: "chick peas in olive oil & lemon juice",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Lebneh",
        ingredients: "white cream chees",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Bayd awarwa",
        ingredients: "eggs & minced lamb baked in traditional clay pans",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Bayd bel soujouk",
        ingredients:
          "eggs and spicy Armenian sausages baked in traditional clay pans",
        price: 7,
        nuts: false,
        spicy: true,
        vegeterian: false,
      },
    ],
    Manakish: [
      {
        name: "Zaatar",
        ingredients:
          "Lebanese pastry: thyme, herbs, sesame seeds, mixed with olive oil",
        price: 3,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Zaatar Jibneh",
        ingredients: "Lebanese pastry: thyme & akkawi cheese",
        price: 3.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Zaatar & Labneh",
        ingredients: "Lebanese pastry: thyme & white cream cheese",
        price: 3.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Zaatar & Halloumi",
        ingredients: "Lebanese pastry: thyme & halloumi cheese",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Jibneh",
        ingredients: "Lebanese pastry with akkawi cheese",
        price: 3.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Jibneh Harra",
        ingredients: "Lebanese pastry with spicy akkawi",
        price: 4,
        nuts: false,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Halloumi",
        ingredients: "Lebanese pastry: halloumi cheese & sesame seeds",
        price: 4,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Sabanegh",
        ingredients: "Lebanese pastry folded and stuff with spinach and onion",
        price: 3.3,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Sabanegh & Halloumi",
        ingredients:
          "Lebanese pastry folded and stuff with spinach, onion and halloumi",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Sabanegh & Cheese",
        ingredients:
          "Lebanese pastry folded and stuff with spinach, onion and akkawi cheese",
        price: 3.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Lahme Bel Ajine",
        ingredients:
          "Lebanese pastry: minced lamb with crushed tomatoes and onion",
        price: 3.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lahme Armanieh",
        ingredients:
          "Lebanese pastry: chilly minced lamb, parsely, crushed tomatoes and onion",
        price: 4,
        nuts: false,
        spicy: true,
        vegeterian: false,
      },
      {
        name: "Kishek",
        ingredients:
          "Lebanese pastry: natural yogurt, cracked wheat shells mixed with onion and tomatoes",
        price: 4,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Kaftah Bil Ajine",
        ingredients: "Lebanese pastry: minced lamb, parsley & onion",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Labneh Awarma",
        ingredients:
          "Lebanese pastry: white cream cheese & seasoned minced lamb",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sujouk w bayd",
        ingredients: "Lebanese pastry: spicy Armerian sausages and egg",
        price: 5.5,
        nuts: false,
        spicy: true,
        vegeterian: false,
      },
      {
        name: "Awarma w bayd",
        ingredients: "Lebanese pastry: seasoned minces lamb and egg",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    "Cold Mezze": [
      {
        name: "Hummus",
        ingredients: "puree of chick peas with sesame oil and lemon juice",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Hummus beiruty",
        ingredients:
          "puree of chick peas with sesame oil, lemon juice, garlic, parsley and chilli",
        price: 6.5,
        nuts: false,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Moutabal",
        ingredients:
          "puree of grilled aubergine with sesame oil and lemon juice",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Batinjan el rahib",
        ingredients: "grilled aubergine with lemon and garlic",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Muhammara",
        ingredients: "mixed spicy nuts and olive oil",
        price: 7.5,
        nuts: true,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Shankleesh",
        ingredients:
          "aged cheese with thyme served with tomatoes, onion and green pepper",
        price: 5.8,
        nuts: false,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Warak enab",
        ingredients:
          "vine leaves stuffed with rice, tomatoes, onion, parsley and spices",
        price: 6.2,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Labneh bel toum",
        ingredients: "white cream cheese with fresh garlic and dried mint",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Loubieh bel zeit",
        ingredients: "fresh green beans cooked in a tomato sauce and olive oil",
        price: 5.9,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Mousakaat batenjan",
        ingredients:
          "oven baked aubergine with tomatoes, onions and chick peas",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Moudardara",
        ingredients:
          "lentils and rice cooked in olive oil and spices finished with caramelized onion topping",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Makdous",
        ingredients:
          "picked baby aubergine stuffed with walnuts, garlic and spices",
        price: 6.5,
        nuts: true,
        spicy: false,
        vegeterian: true,
      },
    ],
    "Hot Mezze": [
      {
        name: "Hummus awarma",
        ingredients: "hummus topped with minced lamb cooked with spices",
        price: 8.5,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Hummus ras asfour",
        ingredients: "hummus topped with diced cubes of lamb filet",
        price: 9,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Hummus shawarma",
        ingredients: "hummus topped with slices of marinated lamb",
        price: 8.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Falafel",
        ingredients:
          "mixture of crushed chick peas and broad beans toasted in spices",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Sfeeha baalbakieh",
        ingredients:
          "oven baked pastry stuffed with minced lamb, crushed tomatoes and onions with pine kernels",
        price: 5.9,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Fatayer",
        ingredients:
          "oven baked pastry stuffed with spinach, onion, herbs and lemon",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Sambousek lahem",
        ingredients:
          "fried pastry filled with minced lamb, onion and pine kernels",
        price: 5.8,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sambousek jibneh",
        ingredients: "fried pastry filled with Lebanese cream cheese and herbs",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Kibbeh lahem",
        ingredients:
          "fried lamb and crushed wheat shells filled with seasoned minced lamb and onions",
        price: 6.5,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kibbeh Yakteen",
        ingredients:
          "fried pumpkin and crushed wheat shells filled with seasoned spinach and chick peas",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Batata harra",
        ingredients: "diced potatoes cooked with peppers, coriander and spices",
        price: 5.8,
        nuts: false,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Makanek",
        ingredients:
          "signature Lebanese-style lamb and pine kernel sausages with lemon juice",
        price: 6.8,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Cheese rikakat",
        ingredients: "friad filo pastry filled with halloumi cheese",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Arayes",
        ingredients: "Labanese bread filled with kafta",
        price: 7.2,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sawda dajaj",
        ingredients: "tangy marinated fried chicken liver",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Soujouk",
        ingredients: "Armenian spicy sausages with garlic",
        price: 6.5,
        nuts: false,
        spicy: true,
        vegeterian: false,
      },
      {
        name: "Jawaneh",
        ingredients: "grilled chicked wings with garlic sauce",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Grilled halloumi",
        ingredients: "stove-top pan grilled halloumi chunks",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Kallaj",
        ingredients: "halloumi cheese grilled on pitta bread",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    Soups: [
      {
        name: "Lentil soup",
        ingredients:
          "Known in the Middle East as Shortbet El Adas, this is a cooks-itself, hearty,delicious lentils soup. Served with lemon wedges and fried pitta bread as croutons",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Chicken & Vegetable Soup",
        ingredients:
          "a hearty chicken broth soup with roast chicken and stir-fry vegetables; full of all the flavors of a roast chicken dinner",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    Sides: [
      {
        name: "French Fries",
        ingredients: "",
        price: 4,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Rice",
        ingredients: "",
        price: 4,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kabis",
        ingredients: "a selection of Labanese pickles and olives",
        price: 3,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    Salads: [
      {
        name: "Tabouleh",
        ingredients:
          "super fresh herb and bulgur salat, with finely chopped parsley; dotted with diced onion and tomato, and dressed with olive oil and lemon juice",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Fattoush",
        ingredients:
          "a fresh and bright chopped green Mediterraean salad dressed in zesty lime juice, with fried homamade pita chips as croutons",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Greek Salad",
        ingredients:
          "cubes of fetta cheese and olives; tossed on a bed of lettuce, cucumbers, parsley, mint, peppers and tomatoes; drizzled in lemon juice and olive oil",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Halloumi & Tomato Salad",
        ingredients:
          "halloumi cheese tossed with cherry tomatoes; a warming fresh mint & olive oil dressing makes this vibrant salad stringy, salty and deliciously hearty",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Green Salad",
        ingredients:
          "a fresh mixture of chopped greens including lettuce, cucumber, parsley, mint, green peppers tossed in lemon juice and olive oil",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Mixed Salad",
        ingredients:
          "keep it clean and eat your greens- this salad is a clone of the green salad, plus the tomatoes minus the parsley!",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    "Main Course": [
      {
        name: "Lahem Meshwi",
        ingredients:
          "lamb fillet cubes served with onion and tomatoes on skewers",
        price: 16.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kafta Meshwiye",
        ingredients: "minced lamb with onion, parsley and spices on skewers",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Shish Taouk",
        ingredients: "marinated pieces of chicken fillets on skewers",
        price: 13.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mixed Grill",
        ingredients:
          "a selection of skewers: lamb fillet cubs, chicken fillets and minced labm(kafta)",
        price: 17.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Farrouj Meshwi",
        ingredients: "baby chicken marinated with garlic and lemon",
        price: 13.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kaftah Khashkhash",
        ingredients: "grilled minced lamb with spicy tomato sause",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Castaleta Ghanam",
        ingredients: "grilled marinated lamb cutlets",
        price: 15.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lamb Shawarma",
        ingredients: "thin slices of spiced and marinated lamb",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Chicken shawarma",
        ingredients:
          "thin slices of spieced and marinated chicken tenderly roasted",
        price: 13.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mixed Shawarma",
        ingredients:
          "thin slices of spiced and marinated lamb and chicken tenderly roasted",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Loubieh & Rice",
        ingredients:
          "one of the most popular vegeterian summer dishes in Lebanon - green beans cooked with tomatoes and olive oil",
        price: 9.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Batenjan & Rice",
        ingredients:
          "healthy, packed with protein, probiotics and gluten free friendly: thid delicious dish is made up of aubergines cooked with tomatoes, onions and chick peas",
        price: 10.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Dish Of The Day",
        ingredients: "ask for the chef`s special",
        price: 10.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    "Fish & Seafood": [
      {
        name: "Grilled Sea Bass",
        ingredients: "served with tahini sauce",
        price: 13.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Samak Bizri",
        ingredients: "fried white bait",
        price: 11,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    Wraps: [
      {
        name: "Falafel",
        ingredients:
          "falafel with tomato, turnip, lettuce, mint, chopped parsley & tahini sauce",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Shish Taouk",
        ingredients:
          "chicken cubes with tomato, luttuce, cucumber pickles & garlic sauce",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lahem Meshwi",
        ingredients:
          "lamb cubes with hummus, tomato, onion, cucumber pickles & parsley",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kafta",
        ingredients:
          "kafta with hummus, tomato, onion, cucumber pickles & parsley",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Chicken shawarma",
        ingredients:
          " chicken shawarma with tomato, lettuce, cucumber pickles & garlic sauce",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lamb shawarma",
        ingredients:
          "lamb shawarma with tomato, onion, cucumber pickles, parsley & tahini",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Makanek",
        ingredients:
          "Labanese sausages with tomato, lettuce, cucumber pickles & garlic sauce",
        price: 6.5,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sawda Dajaj",
        ingredients:
          "chicken liver with tomato, lettuce, cucumber pickles & garlic sauce",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Makali",
        ingredients: "fried aubergines with cauliflower, fries & lettuce",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Halloumi",
        ingredients: "grilled halloumi with tomato, cucumber & mint",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Labneh",
        ingredients: "with cream cheese with tomato, cucumber & mint",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    "Fresh Juice": [
      {
        name: "Orange",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Carrot",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Apple",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lemonade",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Adam & Eve",
        ingredients: "carrot & apple",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Eye Opener",
        ingredients: "banana, orange & grape",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lazy Days",
        ingredients: "orange & mint",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Tropicana",
        ingredients: "pineapple, mango, melon, orange & kiwi",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mega Mix",
        ingredients: "banana, pinapple, blueberry & orange",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mango Mania",
        ingredients: "mango, banana & orange",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Vitamin Plus",
        ingredients: "carrot, orange & ginger",
        price: 5.2,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    "Hot Drinks": [
      {
        name: "Lebanese Coffee",
        ingredients: "",
        price: 3.2,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Americano",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Latte",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Espresso",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Double Espresso",
        ingredients: "",
        price: 3.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Cappuccino",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Hot Chocolate",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Black Tea",
        ingredients: "",
        price: 2,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mint Tea",
        ingredients: "",
        price: 2.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "White Tea",
        ingredients: "",
        price: 2.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    Water: [
      {
        name: "Mineral Water",
        ingredients: "",
        price: [3, 5.5],
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sparking Water",
        ingredients: "",
        price: [3, 5.5],
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    "Cold Drinks": [
      {
        name: "Soft Drink",
        ingredients: "choice of Coca-Cola, Diet Coke, 7up or Tango",
        price: 3,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Yogurt Drink (ayran)",
        ingredients: "",
        price: 3,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    Desserts: [
      {
        name: "Knefeh",
        ingredients:
          "crean cheese topped with semolina, served with syrup in kaakeh",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Baklawa",
        ingredients: "traditional Lebanese pastries filed with nuts & syrup",
        price: 4.2,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Dessert of the day",
        ingredients: "ask your waiter for info",
        price: 4,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
  },
});

restaurants.push({
  name: "West Hampstead",
  openingTimes: {
    monday: "12:00 - 23:00",
    tuesday: "12:00 - 23:00",
    wednesday: "12:00 - 23:00",
    thursday: "12:00 - 23:00",
    friday: "12:00 - 23:00",
    saturday: "12:00 - 23:00",
    sunday: "12:00 - 23:00",
  },
  address: "202 West End Ln, London NW6 1SG",
  phone: "020 3602 0862",
  deliveroo:
    "https://deliveroo.co.uk/menu/london/west-hampstead/the-cedar-west-hampstead",
  uberEats:
    "https://www.ubereats.com/gb/store/the-cedar-lebanese-west-hampstead/zyCJSJlqTTWXEex6lQazEw",
  path: "/menus/West-Hampstead-Menu.pdf",
  menu: {
    Terwika: [
      {
        name: "Shakshouka",
        ingredients: "eggs, tomato and onion",
        price: 6.3,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Mousabaha",
        ingredients: "chick peas in olive oil, lemon juice and tahiti sause",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Foul Modammas",
        ingredients:
          "tenderly cooked brood beans served in olive oil and lemon juice",
        price: 7.2,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Balilah",
        ingredients: "chick peas in olive oil & lemon juice",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Labneh",
        ingredients: "white cream chees",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Bayd awarwa",
        ingredients: "eggs & minced lamb baked in traditional clay pans",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Bayd bel soujouk",
        ingredients:
          "eggs and spicy Armenian sausages baked in traditional clay pans",
        price: 7,
        nuts: false,
        spicy: true,
        vegeterian: false,
      },
      {
        name: "Mini zaatar",
        ingredients:
          "pastry topped with thyme, herbs, sesame seeds & olive oil (6 pcs)",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Mini halloumi",
        ingredients:
          "pastry topped with melted halloumi cheese & sesame seeds (6 pcs)",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    Wraps: [
      {
        name: "Falafel",
        ingredients:
          "falafel with tomato, turnip, lettuce, mint, chopped parsley & tahini sauce",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Shish Taouk",
        ingredients:
          "chicken cubes with tomato, luttuce, cucumber pickles & garlic sauce",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lahem Meshwi",
        ingredients:
          "lamb cubes with hummus, tomato, onion, cucumber pickles & parsley",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kafta",
        ingredients:
          "kafta with hummus, tomato, onion, cucumber pickles & parsley",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Chicken shawarma",
        ingredients:
          " chicken shawarma with tomato, lettuce, cucumber pickles & garlic sauce",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lamb shawarma",
        ingredients:
          "lamb shawarma with tomato, onion, cucumber pickles, parsley & tahini",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Makanek",
        ingredients:
          "Labanese sausages with tomato, lettuce, cucumber pickles & garlic sauce",
        price: 6.5,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sawda Dajaj",
        ingredients:
          "chicken liver with tomato, lettuce, cucumber pickles & garlic sauce",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Makali",
        ingredients: "fried aubergines with cauliflower, fries & lettuce",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Halloumi",
        ingredients: "grilled halloumi with tomato, cucumber & mint",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Labneh",
        ingredients: "with cream cheese with tomato, cucumber & mint",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    "Cold Mezze": [
      {
        name: "Hummus",
        ingredients: "puree of chick peas with sesame oil and lemon juice",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Hummus beiruty",
        ingredients:
          "puree of chick peas with sesame oil, lemon juice, garlic, parsley and chilli",
        price: 6.5,
        nuts: false,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Moutabal",
        ingredients:
          "puree of grilled aubergine with sesame oil and lemon juice",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Batinjan el rahib",
        ingredients: "grilled aubergine with lemon and garlic",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Muhammara",
        ingredients: "mixed spicy nuts and olive oil",
        price: 7.5,
        nuts: true,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Shankleesh",
        ingredients:
          "aged cheese with thyme served with tomatoes, onion and green pepper",
        price: 5.8,
        nuts: false,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Warak enab",
        ingredients:
          "vine leaves stuffed with rice, tomatoes, onion, parsley and spices",
        price: 6.2,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Labneh bel toum",
        ingredients: "white cream cheese with fresh garlic and dried mint",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Loubieh bel zeit",
        ingredients: "fresh green beans cooked in a tomato sauce and olive oil",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Mousakaat batenjan",
        ingredients:
          "oven baked aubergine with tomatoes, onions and chick peas",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Moudardara",
        ingredients:
          "lentils and rice cooked in olive oil and spices finished with caramelized onion topping",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Makdous",
        ingredients:
          "picked baby aubergine stuffed with walnuts, garlic and spices",
        price: 6.5,
        nuts: true,
        spicy: false,
        vegeterian: true,
      },
    ],
    "Hot Mezze": [
      {
        name: "Hummus awarma",
        ingredients: "hummus topped with minced lamb cooked with spices",
        price: 8.5,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Hummus ras asfour",
        ingredients: "hummus topped with diced cubes of lamb filet",
        price: 9,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Hummus shawarma",
        ingredients: "hummus topped with slices of marinated lamb",
        price: 8.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Falafel",
        ingredients:
          "mixture of crushed chick peas and broad beans toasted in spices",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Sfeeha baalbakieh",
        ingredients:
          "oven baked pastry stuffed with minced lamb, crushed tomatoes and onions with pine kernels",
        price: 5.9,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Fatayer",
        ingredients:
          "oven baked pastry stuffed with spinach, onion, herbs and lemon",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Sambousek lahem",
        ingredients:
          "fried pastry filled with minced lamb, onion and pine kernels",
        price: 5.8,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sambousek jibneh",
        ingredients: "fried pastry filled with Lebanese cream cheese and herbs",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Kibbeh lahem",
        ingredients:
          "fried lamb and crushed wheat shells filled with seasoned minced lamb and onions",
        price: 6.5,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kibbeh Yakteen",
        ingredients:
          "fried pumpkin and crushed wheat shells filled with seasoned spinach and chick peas",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Batata harra",
        ingredients: "diced potatoes cooked with peppers, coriander and spices",
        price: 5.8,
        nuts: false,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Makanek",
        ingredients:
          "signature Lebanese-style lamb and pine kernel sausages with lemon juice",
        price: 6.8,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Cheese rikakat",
        ingredients: "friad filo pastry filled with halloumi cheese",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Arayes",
        ingredients: "Labanese bread filled with kafta",
        price: 7.2,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sawda dajaj",
        ingredients: "tangy marinated fried chicken liver",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Soujouk",
        ingredients: "Armenian spicy sausages with garlic",
        price: 6.8,
        nuts: false,
        spicy: true,
        vegeterian: false,
      },
      {
        name: "Jawaneh",
        ingredients: "grilled chicked wings with garlic sauce",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Grilled halloumi",
        ingredients: "stove-top pan grilled halloumi chunks",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Kallaj",
        ingredients: "halloumi cheese grilled on pitta bread",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    "Main Course": [
      {
        name: "Lahem Meshwi",
        ingredients:
          "lamb fillet cubes served with onion and tomatoes on skewers",
        price: 16.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kafta Meshwiye",
        ingredients: "minced lamb with onion, parsley and spices on skewers",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Shish Taouk",
        ingredients: "marinated pieces of chicken fillets on skewers",
        price: 13.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mixed Grill",
        ingredients:
          "a selection of skewers: lamb fillet cubs, chicken fillets and minced labm(kafta)",
        price: 17.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Farrouj Meshwi",
        ingredients: "baby chicken marinated with garlic and lemon",
        price: 13.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kaftah Khashkhash",
        ingredients: "grilled minced lamb with spicy tomato sause",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Castaleta Ghanam",
        ingredients: "grilled marinated lamb cutlets",
        price: 15.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lamb Shawarma",
        ingredients: "thin slices of spiced and marinated lamb",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Chicken shawarma",
        ingredients:
          "thin slices of spieced and marinated chicken tenderly roasted",
        price: 13.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mixed Shawarma",
        ingredients:
          "thin slices of spiced and marinated lamb and chicken tenderly roasted",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Loubieh & Rice",
        ingredients:
          "one of the most popular vegeterian summer dishes in Lebanon - green beans cooked with tomatoes and olive oil",
        price: 9.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Batenjan & Rice",
        ingredients:
          "healthy, packed with protein, probiotics and gluten free friendly: thid delicious dish is made up of aubergines cooked with tomatoes, onions and chick peas",
        price: 10.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    "Fish & Seafood": [
      {
        name: "Prawns Provencial",
        ingredients: "pan fried prawns with tomato, garlic, coriander & lemon",
        price: 8.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Samki Harra",
        ingredients:
          "barked cod, served with spicy ratatouille: peppers, chilli, garlic, tomato sauce, onios & pine kernels",
        price: 8.5,
        nuts: true,
        spicy: true,
        vegeterian: false,
      },
      {
        name: "Octopus Tomato",
        ingredients:
          "tender cooked octopus with garlic, tomato, coriander and lemon",
        price: 8.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Spicy Octopus",
        ingredients:
          "tender cooked octopus with garlic, chili, lemon & parsley",
        price: 8.8,
        nuts: false,
        spicy: true,
        vegeterian: false,
      },
      {
        name: "Grilled Sea Bass",
        ingredients: "served with tahini sauce",
        price: 13.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Grilled Sea Bream",
        ingredients: "served with tahini sauce",
        price: 13.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kibbeh Samak",
        ingredients:
          "deep fried fish & crushed wheat-shell filled with seasoned fish flakes, onion & pine kernels",
        price: 8.5,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Samak Bizri",
        ingredients: "fried white bait",
        price: 11,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Samak Mikli",
        ingredients: `chef's choice of Market Fish (ask for info)`,
        price: 13.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Cod Tajen",
        ingredients: `slow cooked cod in clay pot with tahini, onion & pine kernels`,
        price: 8.5,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
    ],
    Soups: [
      {
        name: "Lentil soup",
        ingredients:
          "Known in the Middle East as Shortbet El Adas, this is a cooks-itself, hearty,delicious lentils soup. Served with lemon wedges and fried pitta bread as croutons",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Chicken & Vegetable Soup",
        ingredients:
          "a hearty chicken broth soup with roast chicken and stir-fry vegetables; full of all the flavors of a roast chicken dinner",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    Sides: [
      {
        name: "French Fries",
        ingredients: "",
        price: 3.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Rice",
        ingredients: "",
        price: 3.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kabis",
        ingredients: "a selection of Labanese pickles and olives",
        price: 3.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    Salads: [
      {
        name: "Tabouleh",
        ingredients:
          "super fresh herb and bulgur salat, with finely chopped parsley; dotted with diced onion and tomato, and dressed with olive oil and lemon juice",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Fattoush",
        ingredients:
          "a fresh and bright chopped green Mediterraean salad dressed in zesty lime juice, with fried homamade pita chips as croutons",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Greek Salad",
        ingredients:
          "cubes of fetta cheese and olives; tossed on a bed of lettuce, cucumbers, parsley, mint, peppers and tomatoes; drizzled in lemon juice and olive oil",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Halloumi & Tomato Salad",
        ingredients:
          "halloumi cheese tossed with cherry tomatoes; a warming fresh mint & olive oil dressing makes this vibrant salad stringy, salty and deliciously hearty",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Green Salad",
        ingredients:
          "a fresh mixture of chopped greens including lettuce, cucumber, parsley, mint, green peppers tossed in lemon juice and olive oil",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Mixed Salad",
        ingredients:
          "keep it clean and eat your greens- this salad is a clone of the green salad, plus the tomatoes minus the parsley!",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    "Fresh Juice": [
      {
        name: "Orange",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Carrot",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Apple",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lemonade",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Adam & Eve",
        ingredients: "carrot & apple",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Eye Opener",
        ingredients: "banana, orange & grape",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lazy Days",
        ingredients: "orange & mint",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Tropicana",
        ingredients: "pineapple, mango, melon, orange & kiwi",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mega Mix",
        ingredients: "banana, pinapple, blueberry & orange",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mango Mania",
        ingredients: "mango, banana & orange",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Vitamin Plus",
        ingredients: "carrot, orange & ginger",
        price: 5.2,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    "Hot Drinks": [
      {
        name: "Lebanese Coffee",
        ingredients: "",
        price: 2.3,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Americano",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Latte",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Espresso",
        ingredients: "",
        price: 2.1,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Double Espresso",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Cappuccino",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Hot Chocolate",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Black Tea",
        ingredients: "",
        price: 2,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mint Tea",
        ingredients: "",
        price: 2.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "White Tea",
        ingredients: "",
        price: 2.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    Water: [
      {
        name: "Mineral Water",
        ingredients: "",
        price: [3, 5.5],
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sparking Water",
        ingredients: "",
        price: [3, 5.5],
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    "Cold Drinks": [
      {
        name: "Soft Drink",
        ingredients: "choice of Coca-Cola, Diet Coke, 7up or Tango",
        price: 3,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Yogurt Drink (ayran)",
        ingredients: "",
        price: 3,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    Desserts: [
      {
        name: "Knefeh",
        ingredients:
          "crean cheese topped with semolina, served with syrup in kaakeh",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Baklawa",
        ingredients: "traditional Lebanese pastries filed with nuts & syrup",
        price: 4.2,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Dessert of the day",
        ingredients: "ask your waiter for info",
        price: 4,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
  },
});

restaurants.push({
  name: "Kensal Green",
  openingTimes: {
    monday: "08:00 - 23:00",
    tuesday: "08:00 - 23:00",
    wednesday: "08:00 - 23:00",
    thursday: "08:00 - 23:00",
    friday: "08:00 - 23:00",
    saturday: "08:00 - 23:00",
    sunday: "08:00 - 23:00",
  },
  address: "58 College Road, London NW10 5ET",
  phone: "0203 417 8383",
  deliveroo: "https://deliveroo.co.uk/",
  uberEats: "https://www.ubereats.com/",
  nowOpen: false,
  path: "/menus/Kensal-Green-Menu.pdf",
  menu: {
    Terwika: [
      {
        name: "Shakshouka",
        ingredients: "eggs, tomato and onion",
        price: 6.3,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Mousabaha",
        ingredients: "chick peas in olive oil, lemon juice and tahiti sause",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Foul Modammas",
        ingredients:
          "tenderly cooked brood beans served in olive oil and lemon juice",
        price: 7.2,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Balilah",
        ingredients: "chick peas in olive oil & lemon juice",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Lebneh",
        ingredients: "white cream chees",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Bayd awarwa",
        ingredients: "eggs & minced lamb baked in traditional clay pans",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Bayd bel soujouk",
        ingredients:
          "eggs and spicy Armenian sausages baked in traditional clay pans",
        price: 7,
        nuts: false,
        spicy: true,
        vegeterian: false,
      },
    ],
    Manakish: [
      {
        name: "Zaatar",
        ingredients:
          "Lebanese pastry: thyme, herbs, sesame seeds, mixed with olive oil",
        price: 3,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Zaatar Jibneh",
        ingredients: "Lebanese pastry: thyme & akkawi cheese",
        price: 3.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Zaatar & Labneh",
        ingredients: "Lebanese pastry: thyme & white cream cheese",
        price: 3.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Zaatar & Halloumi",
        ingredients: "Lebanese pastry: thyme & halloumi cheese",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Jibneh",
        ingredients: "Lebanese pastry with akkawi cheese",
        price: 3.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Jibneh Harra",
        ingredients: "Lebanese pastry with spicy akkawi",
        price: 4,
        nuts: false,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Sabanegh",
        ingredients: "Lebanese pastry folded and stuff with spinach and onion",
        price: 3.3,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Sabanegh & Halloumi",
        ingredients:
          "Lebanese pastry folded and stuff with spinach, onion and halloumi",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Sabanegh & Cheese",
        ingredients:
          "Lebanese pastry folded and stuff with spinach, onion and akkawi cheese",
        price: 3.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Lahme Bel Ajine",
        ingredients:
          "Lebanese pastry: minced lamb with crushed tomatoes and onion",
        price: 3.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lahme Armanieh",
        ingredients:
          "Lebanese pastry: chilly minced lamb, parsely, crushed tomatoes and onion",
        price: 4,
        nuts: false,
        spicy: true,
        vegeterian: false,
      },
      {
        name: "Kishek",
        ingredients:
          "Lebanese pastry: natural yogurt, cracked wheat shells mixed with onion and tomatoes",
        price: 4,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Kaftah Bil Ajine",
        ingredients: "Lebanese pastry: minced lamb, parsley & onion",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Labneh Awarma",
        ingredients:
          "Lebanese pastry: white cream cheese & seasoned minced lamb",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sujouk w bayd",
        ingredients: "Lebanese pastry: spicy Armerian sausages and egg",
        price: 5.5,
        nuts: false,
        spicy: true,
        vegeterian: false,
      },
      {
        name: "Awarma w bayd",
        ingredients: "Lebanese pastry: seasoned minces lamb and egg",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    "Cold Mezze": [
      {
        name: "Hummus",
        ingredients: "puree of chick peas with sesame oil and lemon juice",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Hummus beiruty",
        ingredients:
          "puree of chick peas with sesame oil, lemon juice, garlic, parsley and chilli",
        price: 6.5,
        nuts: false,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Moutabal",
        ingredients:
          "puree of grilled aubergine with sesame oil and lemon juice",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Batinjan el rahib",
        ingredients: "grilled aubergine with lemon and garlic",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Muhammara",
        ingredients: "mixed spicy nuts and olive oil",
        price: 7.5,
        nuts: true,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Shankleesh",
        ingredients:
          "aged cheese with thyme served with tomatoes, onion and green pepper",
        price: 5.8,
        nuts: false,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Warak enab",
        ingredients:
          "vine leaves stuffed with rice, tomatoes, onion, parsley and spices",
        price: 6.2,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Labneh bel toum",
        ingredients: "white cream cheese with fresh garlic and dried mint",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Loubieh bel zeit",
        ingredients: "fresh green beans cooked in a tomato sauce and olive oil",
        price: 5.9,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Mousakaat batenjan",
        ingredients:
          "oven baked aubergine with tomatoes, onions and chick peas",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Moudardara",
        ingredients:
          "lentils and rice cooked in olive oil and spices finished with caramelized onion topping",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Makdous",
        ingredients:
          "picked baby aubergine stuffed with walnuts, garlic and spices",
        price: 6.5,
        nuts: true,
        spicy: false,
        vegeterian: true,
      },
    ],
    "Hot Mezze": [
      {
        name: "Hummus awarma",
        ingredients: "hummus topped with minced lamb cooked with spices",
        price: 8.5,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Hummus ras asfour",
        ingredients: "hummus topped with diced cubes of lamb filet",
        price: 9,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Hummus shawarma",
        ingredients: "hummus topped with slices of marinated lamb",
        price: 8.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Falafel",
        ingredients:
          "mixture of crushed chick peas and broad beans toasted in spices",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Sfeeha baalbakieh",
        ingredients:
          "oven baked pastry stuffed with minced lamb, crushed tomatoes and onions with pine kernels",
        price: 5.9,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Fatayer",
        ingredients:
          "oven baked pastry stuffed with spinach, onion, herbs and lemon",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Sambousek lahem",
        ingredients:
          "fried pastry filled with minced lamb, onion and pine kernels",
        price: 5.8,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sambousek jibneh",
        ingredients: "fried pastry filled with Lebanese cream cheese and herbs",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Kibbeh lahem",
        ingredients:
          "fried lamb and crushed wheat shells filled with seasoned minced lamb and onions",
        price: 6.5,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kibbeh Yakteen",
        ingredients:
          "fried pumpkin and crushed wheat shells filled with seasoned spinach and chick peas",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Batata harra",
        ingredients: "diced potatoes cooked with peppers, coriander and spices",
        price: 5.8,
        nuts: false,
        spicy: true,
        vegeterian: true,
      },
      {
        name: "Makanek",
        ingredients:
          "signature Lebanese-style lamb and pine kernel sausages with lemon juice",
        price: 6.8,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Cheese rikakat",
        ingredients: "friad filo pastry filled with halloumi cheese",
        price: 5.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Arayes",
        ingredients: "Labanese bread filled with kafta",
        price: 7.2,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sawda dajaj",
        ingredients: "tangy marinated fried chicken liver",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Soujouk",
        ingredients: "Armenian spicy sausages with garlic",
        price: 6.8,
        nuts: false,
        spicy: true,
        vegeterian: false,
      },
      {
        name: "Jawaneh",
        ingredients: "grilled chicked wings with garlic sauce",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Grilled halloumi",
        ingredients: "stove-top pan grilled halloumi chunks",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Kallaj",
        ingredients: "halloumi cheese grilled on pitta bread",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    Soups: [
      {
        name: "Lentil soup",
        ingredients:
          "Known in the Middle East as Shortbet El Adas, this is a cooks-itself, hearty,delicious lentils soup. Served with lemon wedges and fried pitta bread as croutons",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Chicken & Vegetable Soup",
        ingredients:
          "a hearty chicken broth soup with roast chicken and stir-fry vegetables; full of all the flavors of a roast chicken dinner",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    Sides: [
      {
        name: "French Fries",
        ingredients: "",
        price: 3.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Rice",
        ingredients: "",
        price: 3.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kabis",
        ingredients: "a selection of Labanese pickles and olives",
        price: 3.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    Salads: [
      {
        name: "Tabouleh",
        ingredients:
          "super fresh herb and bulgur salat, with finely chopped parsley; dotted with diced onion and tomato, and dressed with olive oil and lemon juice",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Fattoush",
        ingredients:
          "a fresh and bright chopped green Mediterraean salad dressed in zesty lime juice, with fried homamade pita chips as croutons",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Greek Salad",
        ingredients:
          "cubes of fetta cheese and olives; tossed on a bed of lettuce, cucumbers, parsley, mint, peppers and tomatoes; drizzled in lemon juice and olive oil",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Halloumi & Tomato Salad",
        ingredients:
          "halloumi cheese tossed with cherry tomatoes; a warming fresh mint & olive oil dressing makes this vibrant salad stringy, salty and deliciously hearty",
        price: 6.8,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Green Salad",
        ingredients:
          "a fresh mixture of chopped greens including lettuce, cucumber, parsley, mint, green peppers tossed in lemon juice and olive oil",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Mixed Salad",
        ingredients:
          "keep it clean and eat your greens- this salad is a clone of the green salad, plus the tomatoes minus the parsley!",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    "Main Course": [
      {
        name: "Lahem Meshwi",
        ingredients:
          "lamb fillet cubes served with onion and tomatoes on skewers",
        price: 16.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kafta Meshwiye",
        ingredients: "minced lamb with onion, parsley and spices on skewers",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Shish Taouk",
        ingredients: "marinated pieces of chicken fillets on skewers",
        price: 13.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mixed Grill",
        ingredients:
          "a selection of skewers: lamb fillet cubs, chicken fillets and minced labm(kafta)",
        price: 17.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Farrouj Meshwi",
        ingredients: "baby chicken marinated with garlic and lemon",
        price: 13.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kaftah Khashkhash",
        ingredients: "grilled minced lamb with spicy tomato sause",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Castaleta Ghanam",
        ingredients: "grilled marinated lamb cutlets",
        price: 15.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lamb Shawarma",
        ingredients: "thin slices of spiced and marinated lamb",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Chicken shawarma",
        ingredients:
          "thin slices of spieced and marinated chicken tenderly roasted",
        price: 13.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mixed Shawarma",
        ingredients:
          "thin slices of spiced and marinated lamb and chicken tenderly roasted",
        price: 14.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Loubieh & Rice",
        ingredients:
          "one of the most popular vegeterian summer dishes in Lebanon - green beans cooked with tomatoes and olive oil",
        price: 9.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Batenjan & Rice",
        ingredients:
          "healthy, packed with protein, probiotics and gluten free friendly: thid delicious dish is made up of aubergines cooked with tomatoes, onions and chick peas",
        price: 10.5,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Dish Of The Day",
        ingredients: "ask for the chef`s special",
        price: 0,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    "Fish & Seafood": [
      {
        name: "Grilled Sea Bass",
        ingredients: "served with tahini sauce",
        price: 13.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Samak Bizri",
        ingredients: "fried white bait",
        price: 11,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    Wraps: [
      {
        name: "Falafel",
        ingredients:
          "falafel with tomato, turnip, lettuce, mint, chopped parsley & tahini sauce",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Shish Taouk",
        ingredients:
          "chicken cubes with tomato, luttuce, cucumber pickles & garlic sauce",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lahem Meshwi",
        ingredients:
          "lamb cubes with hummus, tomato, onion, cucumber pickles & parsley",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Kafta",
        ingredients:
          "kafta with hummus, tomato, onion, cucumber pickles & parsley",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Chicken shawarma",
        ingredients:
          " chicken shawarma with tomato, lettuce, cucumber pickles & garlic sauce",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lamb shawarma",
        ingredients:
          "lamb shawarma with tomato, onion, cucumber pickles, parsley & tahini",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Makanek",
        ingredients:
          "Labanese sausages with tomato, lettuce, cucumber pickles & garlic sauce",
        price: 6.5,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sawda Dajaj",
        ingredients:
          "chicken liver with tomato, lettuce, cucumber pickles & garlic sauce",
        price: 6.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Makali",
        ingredients: "fried aubergines with cauliflower, fries & lettuce",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Halloumi",
        ingredients: "grilled halloumi with tomato, cucumber & mint",
        price: 7,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
      {
        name: "Labneh",
        ingredients: "with cream cheese with tomato, cucumber & mint",
        price: 6,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
    "Fresh Juice": [
      {
        name: "Orange",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Carrot",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Apple",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lemonade",
        ingredients: "",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Adam & Eve",
        ingredients: "carrot & apple",
        price: 4.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Eye Opener",
        ingredients: "banana, orange & grape",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Lazy Days",
        ingredients: "orange & mint",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Tropicana",
        ingredients: "pineapple, mango, melon, orange & kiwi",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mega Mix",
        ingredients: "banana, pinapple, blueberry & orange",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mango Mania",
        ingredients: "mango, banana & orange",
        price: 5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Vitamin Plus",
        ingredients: "carrot, orange & ginger",
        price: 5.2,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    "Hot Drinks": [
      {
        name: "Lebanese Coffee",
        ingredients: "",
        price: 2.3,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Americano",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Latte",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Espresso",
        ingredients: "",
        price: 2.1,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Double Espresso",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Cappuccino",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Hot Chocolate",
        ingredients: "",
        price: 2.8,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Black Tea",
        ingredients: "",
        price: 2,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Mint Tea",
        ingredients: "",
        price: 2.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "White Tea",
        ingredients: "",
        price: 2.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    Water: [
      {
        name: "Mineral Water",
        ingredients: "",
        price: [3, 5.5],
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Sparking Water",
        ingredients: "",
        price: [3, 5.5],
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    "Cold Drinks": [
      {
        name: "Soft Drink",
        ingredients: "choice of Coca-Cola, Diet Coke, 7up or Tango",
        price: 3,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Yogurt Drink (ayran)",
        ingredients: "",
        price: 3,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
    ],
    Desserts: [
      {
        name: "Knefeh",
        ingredients:
          "crean cheese topped with semolina, served with syrup in kaakeh",
        price: 5.5,
        nuts: false,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Baklawa",
        ingredients: "traditional Lebanese pastries filed with nuts & syrup",
        price: 4.2,
        nuts: true,
        spicy: false,
        vegeterian: false,
      },
      {
        name: "Dessert of the day",
        ingredients: "ask your waiter for info",
        price: 4,
        nuts: false,
        spicy: false,
        vegeterian: true,
      },
    ],
  },
});

let catering = {
  name: "The Cedar Catering Services",
  openingTimes: {
    enquiries: "09:00 - 17:00",
  },
  address: "31 Malvern Road, London NW6 5PS",
  phone: "020 7624 4086",
};

let deli = {
  name: "The Deli and Meat",
  openingTimes: {
    monday: "08:00 - 23:00",
    tuesday: "08:00 - 23:00",
    wednesday: "08:00 - 23:00",
    thursday: "08:00 - 23:00",
    friday: "08:00 - 23:00",
    saturday: "08:00 - 23:00",
    sunday: "08:00 - 23:00",
  },
  address: "31 Malvern Road, London NW6 5PS",
  phone: "020 7624 4086",
  deliveroo: "https://deliveroo.co.uk/menu/london/kilburn/the-cedar-express",
  uberEats:
    "https://www.ubereats.com/gb/store/the-cedar-express-west-kilburn/WWurNL5KRZyR_LgOmvzlvw",
  path: "/menus/The-Deli-Menu.pdf",
  menu: {
    "Cold Mezza": [
      {
        name: "Hoummos",
        ingredients: "puree of chick peas with sesame oil and lemon juice",
        price: 5.5,
        vegeterian: true,
      },
      {
        name: "Moutabal",
        ingredients:
          "puree of grilled aubergines with sesame oil and lemon juice",
        price: 6.5,
        vegeterian: true,
      },
      {
        name: "Warak Enab",
        ingredients:
          "vine leaves stuffed with rice, tomatoes, onion, parsley and spices",
        price: 5.5,
        vegeterian: true,
      },
      {
        name: "Labneh",
        ingredients: "white cream cheese",
        price: 5,
        vegeterian: true,
      },
    ],
    "Hot Mezza": [
      {
        name: "Falafel",
        ingredients:
          "mixture of crushed chick peas and broad beans toasted in spices",
        price: [4.5, 9],
        vegeterian: true,
      },
      {
        name: "Sfeeha Baalbakieh",
        ingredients:
          "oven baked pastry stuffed with minced lamb, crushed tomatoes and onions with pine kernels ",
        price: [4.5, 9],
        nuts: true,
      },
      {
        name: "Fatayer",
        ingredients:
          "oven baked pastry stuffed with spinach, onion, herbs and lemon",
        price: [4.5, 9],
        vegeterian: true,
      },
      {
        name: "Sambousek Lahem",
        ingredients:
          "fried pastry filled with minced lamb, onion and pine kernels",
        price: [4.5, 9],
        nuts: true,
      },
      {
        name: "Sambousek Jibneh",
        ingredients: "fried pastry filled with Lebanese cream cheese and herbs",
        price: [4.5, 9],
        vegeterian: true,
      },
      {
        name: "Cheese Rikakat",
        ingredients: "fried pastry filled with halloumi cheese",
        price: [4.5, 9],
        vegeterian: true,
      },
      {
        name: "Kibbeh Lahem",
        ingredients:
          "fried lamb and crushed wheat shells filled with seasoned minced lamb and onions",
        price: [6, 12],
        nuts: true,
      },
      {
        name: "Kibbeh Yakteen",
        ingredients:
          "fried pumpkins and crushed wheat shells filled with seasoned spinach and chick peas",
        price: [6, 12],
        vegeterian: true,
      },
    ],
    Salads: [
      {
        name: "Tabouleh",
        ingredients:
          "super fresh herb and bulgur salad, with finely chopped parsley; dotted with diced onion and tomato, and dressed with olive oil and lemon juice",
        price: 6,
        vegeterian: true,
      },
      {
        name: "Fattoush",
        ingredients:
          "a fresh and bright chopped green Mediterranean salad dressed in zesty lime juice, with fried homemade pita chips as croutons",
        price: 6,
        vegeterian: true,
      },
      {
        name: "Mixed Salad",
        ingredients:
          "keep it clean and eat your greens - this salad is a clone of the green salad, plus the tomatoes minus the parsley!",
        price: 5,
        vegeterian: true,
      },
    ],
    Manakeesh: [
      {
        name: "Zaatar",
        ingredients:
          " Lebanese pastry: thyme, herbs, sesame seeds, and olive oil",
        price: 2.5,
        vegeterian: true,
      },
      {
        name: "Jibneh",
        ingredients: "Lebanese pastry with akkawi cheese",
        price: 3,
        vegeterian: true,
      },
      {
        name: "Halloumi",
        ingredients: "Lebanese pastry with halloumi cheese",
        price: 3.5,
        vegeterian: true,
      },
      {
        name: "Zaatar & Jibneh",
        ingredients: "Lebanese pastry: thyme & akkawi cheese",
        price: 3,
        vegeterian: true,
      },
      {
        name: "Zaatar & Labneh",
        ingredients: "Lebanese pastry: thyme & white cream cheese",
        price: 3,
        vegeterian: true,
      },
      {
        name: "Zaatar & Halloumi",
        ingredients: "Lebanese pastry: thyme & halloumi cheese",
        price: 3.5,
        vegeterian: true,
      },
      {
        name: "Jibneh Harra",
        ingredients: "Lebanese pastry with spisy akkawi",
        price: 3,
        vegeterian: true,
        spicy: true,
      },
      {
        name: "Sabanegh",
        ingredients: "Lebanese pastry folded and stuff with spinach and onion",
        price: 2.5,
        vegeterian: true,
      },
      {
        name: "Sabanegh & Jibneh",
        ingredients:
          "Lebanese pastr folded and stuff with spinach, onion and akkawi cheese",
        price: 3,
        vegeterian: true,
      },
      {
        name: "Lahem Bel Ajeene",
        ingredients:
          "Lebanese pastry: minced lamb with crushed tomatoes and onion",
        price: 3,
      },
      {
        name: "Muhammara",
        ingredients:
          "Lebanese pastry with chilli roasted peppers, garlic, onion and tomatoes",
        price: 3,
        vegeterian: true,
        spicy: true,
      },
      {
        name: "Kafta Bel Ajeene",
        ingredients: "Lebanese pastry with kafta",
        price: 5,
      },
    ],
    "Main Course": [
      {
        name: "Lahem Meshwi",
        ingredients:
          "lamb fillet cubes served with onion and tomatoes on skewers",
        priceDescription: [
          { name: "Skewer", price: 4 },
          { name: "0.5kg", price: 14 },
          { name: "1kg", price: 24 },
        ],
      },
      {
        name: "Kafta Meshwieh",
        ingredients: "minced lamb with onion, parsley and spices on skewers",
        priceDescription: [
          { name: "Skewer", price: 4 },
          { name: "0.5kg", price: 12 },
          { name: "1kg", price: 22 },
        ],
      },
      {
        name: "Shish Taouk",
        ingredients: "marinated pieces of chicken fillets on skewers",
        priceDescription: [
          { name: "Skewer", price: 4 },
          { name: "0.5kg", price: 11 },
          { name: "1kg", price: 20 },
        ],
      },
      {
        name: "Mixed Grill",
        ingredients:
          "a selection of skewers: lamb fillet cubes, chiken fillet and minced lanb (kafta)",
        priceDescription: [
          { name: "0.5kg", price: 13.5 },
          { name: "1kg", price: 22.5 },
        ],
      },
      {
        name: "Farrouj Meshwi",
        ingredients: "baby chicken marinated with garlic and lemon",
        priceDescription: [{ name: "1kg", price: 12 }],
      },
      {
        name: "Castaleta Ghanam",
        ingredients: "grilled marinated lamb cutlets",
        priceDescription: [
          { name: "0.5kg", price: 14 },
          { name: "1kg", price: 24 },
        ],
      },
      {
        name: "Jawaneh",
        ingredients: "grilled chicken wings with garlic sauce",
        priceDescription: [
          { name: "0.5kg", price: 10 },
          { name: "1kg", price: 16 },
        ],
      },
    ],
    Wraps: [
      {
        name: "Falafel",
        ingredients:
          "falafel with tomato, turnip, lettuce, mint, chopped parsley & tahini sauce",
        price: 5,
        vegeterian: true,
      },
      {
        name: "Shish Taouk",
        ingredients:
          "chicken cubes with tomato, lettuce, cucumbers pickles & garlic sauce",
        price: 5.5,
      },
      {
        name: "Lahem Meshwi",
        ingredients:
          "lamb cubes with hummus, tomato, onion, cucumber pickles & parsley",
        price: 6,
      },
      {
        name: "Kafta",
        ingredients:
          "kafta with hummus, tomato, onion, cucumber pickles & parsley",
        price: 5.5,
      },
      {
        name: "Makali",
        ingredients: "fried aubergines with cauliflower, fries & lettuce",
        price: 5,
        vegeterian: true,
      },
      {
        name: "Halloumi",
        ingredients: "grilled halloumi with tomato, cucumber & mint",
        price: 5.5,
        vegeterian: true,
      },
    ],
    Sauces: [
      {
        name: "Garlic",
        priceDescription: [
          { name: "2 oz", price: 1 },
          { name: "8 oz", price: 3 },
          { name: "16 oz", price: 6 },
        ],
      },
      {
        name: "Tahini",
        priceDescription: [
          { name: "2 oz", price: 1 },
          { name: "8 oz", price: 3 },
          { name: "16 oz", price: 6 },
        ],
      },
      {
        name: "Chili",
        priceDescription: [
          { name: "2 oz", price: 1 },
          { name: "8 oz", price: 3 },
          { name: "16 oz", price: 6 },
        ],
        vegeterian: true,
      },
    ],
    Sides: [
      {
        name: "French fries",
        price: 3.5,
      },
      {
        name: "Rice",
        price: 3.5,
      },
      {
        name: "Kabis",
        ingredients: "a selection of Lebanese pickles and olives",
        price: 3.5,
        vegeterian: true,
      },
    ],
    "Fresh Juice": [
      {
        name: "Orange",
        price: 3.5,
      },
      {
        name: "Carrot",
        price: 3.5,
      },
      {
        name: "Apple",
        price: 3.5,
      },
    ],
    "Soft Drinks": [
      {
        name: "Still Water",
        price: 2,
      },
      {
        name: "Soft Drink (can)",
        price: 2,
      },
    ],
    "Hot Drinks": [
      {
        name: "Espresso",
        price: 1.8,
      },
      {
        name: "Double Espresso",
        price: 2.4,
      },
      {
        name: "Latte",
        price: 2.2,
      },
      {
        name: "Black Tea",
        price: 2,
      },
      {
        name: "Mint Tea",
        price: 2.2,
      },
    ],
  },
};

restaurants.push(deli);

export { restaurants, deli, catering };
