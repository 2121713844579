<template>
    <div class="_contactContainer">

        <SectionTitle subtitle="contact" :title="`${restaurant.name}`"/>
        <WavyLine :short="true"/>

        <div class="_container">
            <div class="_map"><iframe class="gMaps" :src="`https://maps.google.com/maps?q=${restaurant.address}&t=&z=17&ie=UTF8&iwloc=&output=embed&SameSite=None`" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div>

            <div class="_contactInfo">
                
                <div class="_openingAndAddress">

                    <div class="_block">
                        <div class="_title">Opening times</div>
                        <div class="_content _openingTimesBlock">
                            <div :key="key" v-for="(row, key) of Object.keys(restaurant.openingTimes)" class="openingTimeRow">
                                <div class="_name">{{ row }}:</div>
                                <div class="_value">{{ restaurant.openingTimes[row] }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="address_phone">
                        <div class="_block">
                            <div class="_title">Address</div>
                            <div class="_content">{{ restaurant.address }}</div>
                        </div>

                         <div class="_block">
                            <div class="_title">Phone</div>
                            <div class="_content"><a :href="`tel: ${restaurant.phone}`">{{ restaurant.phone }}</a></div>
                        </div>
                    </div>
                </div>

                <div class="_block" v-if="restaurant.deliveroo">
                    <div class="_title">Delivery</div>
                    <div class="_content">
                        <div class="_cta">
                            <a :href="restaurant.deliveroo" target="_blank" class="btn filled">Deliveroo</a>
                            <span class="_or">or</span>
                            <a :href="restaurant.uberEats" target="_blank" class="btn filled">UberEats</a>
                        </div>
                    </div>
                </div>

            </div>


        </div>

        <WavyLine/>

    </div>
</template>


<script>
export default {
    props: ['restaurant'],
    mounted() {
        document.cookie = 'SameSite=None; Secure';
    }
}
</script>


<style lang="scss" scoped>

._container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 100px;
    align-items: flex-start;
}

._map {
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    padding-top: 100%;
    position: relative;
    .gMaps {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

._openingAndAddress {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 100px;
    align-items: flex-start;
}

._block {
    display: grid;
    row-gap: 20px;
    ._title {
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        /* identical to box height */
        color: $subtitle;
        letter-spacing: -0.065em;
        text-transform: uppercase;
    }
    ._content {
        font-size: 13px;
        line-height: 19px;
        /* identical to box height */
        text-transform: capitalize;
    }
}

.address_phone {
    display: grid;
    row-gap: 50px;
}

._openingTimesBlock {
    display: grid;
    row-gap: 10px;
}

.openingTimeRow {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    ._name {
        color: $subtitle;
        text-align: left;
    }
    ._value {
        text-align: right;
    }
}

._contactInfo {
    display: grid;
    align-items: flex-start;
    row-gap: 100px;
}

._cta {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    column-gap: 25px;
    max-width: 320px;
    width: 100%;
    align-items: center;
    ._or {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-transform: lowercase;
        /* identical to box height */
        text-align: center;
        color: $greyText;
    }
}


@media screen and (max-width: 1200px) {
    ._container, ._openingAndAddress  {
        column-gap: 50px;
    }

    ._contactInfo {
        row-gap: 50px;
    }
}



@media screen and (max-width: 1000px) {
    ._container  {
        grid-template-columns: 300px minmax(0, 1fr);
    }
}


@media screen and (max-width: 900px) {

     ._container  {
        grid-template-columns: minmax(0, 1fr);
        row-gap: 50px;
    }

    ._map {
        padding-top: 50%;
    }
    



}


@media screen and (max-width: 600px) {

    ._map {
        padding-top: 75%;
    }
    
}



@media screen and (max-width: 520px) {

    ._map {
        padding-top: 100%;
    }

    ._openingAndAddress  {
        grid-template-columns: minmax(0, 1fr);
        row-gap: 50px;
    }

    ._block {
        ._title {
            text-align: center;
        }
        ._content {
            text-align: center;
        }
    }

    ._cta {
        margin: 0 auto;
    }
    
}

</style>