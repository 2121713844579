<template>
    <div class="_wavyLine">
        <img src="/images/decor/wavy-line-short.svg" v-if="isShort">
        <img src="/images/decor/wavy-line.svg" v-else>
    </div>
</template>


<script>
export default {
    props: ['short'],
    data() {
        return {
            isShort: (this.short != null) ? this.short : false
        }
    }
}
</script>

<style lang="scss" scoped>
._wavyLine {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
}
</style>