<template>
    <div>
        <div class="_subtitle">{{ subtitle }}</div>
        <div class="_title">{{ title }}</div>
    </div>
</template>


<script>
export default {
    props: ['subtitle', 'title']
}
</script>


<style lang="scss" scoped>
._subtitle {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */
    letter-spacing: -0.065em;
    text-transform: uppercase;
    color: $subtitle;
    text-align: center;
    margin-bottom: 25px;
}

._title {
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */
    text-align: center;
    text-transform: capitalize;
    color: $greyText;
}
</style>