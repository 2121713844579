<template>
    <div class="hero">
        
        <div class="bgGroup"></div>

        <div class="wrapper">

            <div class="_content">
                <slot/>
            </div>

        </div>

        <div class="_scrollDown">
            <div class="_container">
                <span class="_text">scroll down</span>
                <div class="_line"></div>
            </div>
        </div>

    </div>
</template>

<script>

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);


export default {
    data() {
        return {
            gsapTrigger: null
        }
    },
    mounted() {

        let Alltrigger = ScrollTrigger.getAll()
        for (let i = 0; i < Alltrigger.length; i++) {
            Alltrigger[i].kill(true);
        }

        let nav = document.querySelector("nav");
        nav.classList.remove('fixed');

        ScrollTrigger.create({
            trigger: ".hero",
            markers: false,
            start: "bottom top",
            onEnter: () => {
                nav.classList.add('fixed');
            },
            onLeaveBack: () => {
                nav.classList.remove('fixed');
            },
        });

    }
}
</script>


<style lang="scss" scoped>
.hero {
    background: #fff url('/images/wp.jpeg') center center/cover no-repeat;
    // background: #fff url('/images/hero.jpg') center center/cover no-repeat;
    position: relative;
    z-index: 1;
    .bgGroup {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: rgba(0,0,0,0.4);
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
}

._scrollDown {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    ._container {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        ._line {
            width: 1px;
            height: 150px;
            background: $buttonBG;
        }
        ._text {
            position: absolute;
            bottom: 100%;
            left: 0;
            transform: translate(-50%, -10px);
            width: 57px;
            height: 12px;
            font-style: normal;
            font-weight: 300;
            font-size: 10px;
            line-height: 118%;
            /* identical to box height, or 12px */
            text-align: center;
            text-transform: lowercase;
            color: #fff;
        }
    }
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 200px 30px;
}

._content {
    max-width: 525px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
</style>