import { createRouter, createWebHistory } from "vue-router";
import MainPage from "../views/MainPage.vue";
// import DeliPage from "../views/TheDeli.vue";
import Catering from "../views/Catering.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: MainPage,
  },
  // {
  //   path: '/deli',
  //   name: 'DeliPage',
  //   component: DeliPage
  // },
  {
    path: "/catering",
    name: "Catering",
    component: Catering,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  return next();
});

export default router;
