import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



import AOS from 'aos/dist/aos.js'
import 'aos/dist/aos.css'


let myApp = createApp({
    render: () => h(App),
    created() {
        AOS.init();
    }
});



import WavyLine from './components/WavyLine';
myApp.component('WavyLine', WavyLine);

import SectionTitle from './components/SectionTitle.vue'
myApp.component('SectionTitle', SectionTitle);



myApp.directive('click-outside', {
    beforeMount(el, binding) {
        el.clickOutsideEvent = function(event) {
        if (!(el === event.target || el.contains(event.target))) {
            binding.value(event, el);
        }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

import titleMixin from './mixins/titleMixin'
myApp.mixin(titleMixin);

myApp.use(store).use(router).mount('#app');
