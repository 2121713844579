function getTitle (vm) {
    const { title } = vm.$options
    if (title) {
      return typeof title === 'function'
        ? title.call(vm)
        : title
    }
  }

  export default {
    created () {
      this.setTitle();
    },
    methods: {
      setTitle() {
        const title = getTitle(this)
        if (title) {
          document.title = `${title}`
        }
      }
    },
    watch: {
      $route() {
        this.setTitle();
      }
    }
  }