<template>


<section>
    <Hero class="_hero">
        <div class="ovh mg-b-25"><div class="_subtitle" data-aos="fade-up" data-aos-delay="200">The Cedar Restaurant</div></div>
        <div class="ovh mg-b-30"><h1 class="_title"  data-aos="fade-up" data-aos-delay="400">The Cedar Catering Services</h1></div>
        <div class="ovh"><p class="_description" data-aos="fade-up" data-aos-delay="600">An award winning restaurant that provides outstanding catering services for all your functions and events</p></div>

        <div class="_cta">
            <button class="btn _icon filled" @click="viewMenu" data-aos="fade-up" data-aos-delay="400">
                <span>Find out more</span>
                <span class="icon"><i class="fas fa-arrow-right"></i></span>
            </button>
        </div>
    </Hero>

    <div class="cateringContainer">

        <div class="wrapper _post" id="cateringInfo">

            <SectionTitle class="titleMG" subtitle="the cedar restaurant" title="Catering Services"/>
            <WavyLine :short="true"/>

            <p class="mgb">We are proud to offer our awarding winning food , in case you didn’t know we won UK’s favourite takeaway restaurant in the UK, as a catering service to all small and large events. Whether it’s a wedding or a corporate event we are 100% confident that your guests will be talking about how great the food is.</p>
            <p class="mgb">We cater for weddings, family parties, birthdays, barbecues… we are flexible and professional. The Cedar can create tailor made menus and we also provide chefs to prepare food at a venue if requested. We cater for a small family parties (10-12 to 150-200 people) weddings or anything else, even venues outside London.</p>
            <p>We have literally served over 100s of events with completely satisfied organisers and guests.  This is because we are in a unique position of running awarding winning restaurants and run a deli and meat business that feeds straight into our catering business.  You will be guaranteed a service and selection of food of top quality that no competitor can meet.  Contact us today using the form below for a no commitment conversation about how we can make your special day even more special.</p>
            <WavyLine/>
        </div>


        <div class="wrapper">
            <Contact :restaurant="cateringInfo" />
        </div>
    </div>

</section>

</template>

<script>
import Hero from '../components/home/Hero.vue'
import Contact from '../components/home/Contact.vue'
import { catering } from '../menu'


import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin);

export default {
    components: {
        Hero,
        Contact
    },
    title: 'Catering - The Cedar Restaurant',
    data() {
        return {
            cateringInfo: catering
        }
    },
    methods: {
        viewMenu() {
            gsap.to(window, { duration: 0.3, scrollTo: "#cateringInfo" });
        }
    }
}
</script>


<style lang="scss" scoped>
.cateringContainer {
    padding-top: 200px;
}

.mgb {
    margin-bottom: 20px;
}

.titleMG {
    margin-bottom: 50px;
}

._post {
    max-width: 750px;
    text-align: center;
}


._subtitle {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */
    letter-spacing: -0.065em;
    text-transform: uppercase;
    color: $subtitle;
    text-align: center;
}

._hero {
    ._title {
        font-style: normal;
        font-weight: bold;
        font-size: 44px;
        line-height: 118%;
        /* identical to box height, or 57px */
        text-align: center;
        text-transform: capitalize;
        color: #fff;
    }
    ._description {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        text-transform: capitalize;
        color: rgba(255,255,255,0.9);
        max-width: 420px;
    }
    ._cta {
        margin-top: 50px;
    }
}


</style>
