<template>
    <footer id="footer">
        <div class="wrapper">
            <div class="copyright">© {{ year }} The Cedar Restaurant. All rights Reserved</div>

            <div class="_social">
                <span class="_text">Follow us:</span>
                <a href="https://www.facebook.com/Thecedarrestaurantofficial/" target="_blank" class="_link"><i class="fab fa-facebook-f"></i></a>
                <a href="https://www.instagram.com/thecedarrestaurant/" target="_blank" class="_link"><i class="fab fa-instagram"></i></a>
                <a href="https://twitter.com/cedarlebanese" target="_blank" class="_link"><i class="fab fa-twitter"></i></a>
            </div>
        </div>
    </footer>
</template>

<script>
import moment from 'moment'
export default {
    computed: {
        year() {
            return moment().year();
        }
    }
}
</script>


<style lang="scss" scoped>
.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
    .copyright {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */
        text-transform: capitalize;
    }
    ._social {
        display: flex;
        align-items: center;
        ._text {
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */
            text-transform: capitalize;
            margin-right: 10px;
        }
        ._link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 44px;
            height: 44px;
            text-decoration: none;
            font-size: 18px;
            color: $greyText;
        }
    }
}


@media screen and (max-width: 750px) {

    .wrapper {
        flex-direction: column;
        padding: 20px 30px;
        .copyright {
            margin-bottom: 20px;
            text-align: center;
        }
    }

}


@media screen and (max-width: 350px) {
    ._social {
        ._text {
            display: none;
        }
    }
}



</style>