<template>
  <div class="_menuContainer">
    <SectionTitle
      subtitle="menu"
      :title="`${restaurant.name}${restaurant.nowOpen ? ' • Now Open' : ''}`"
    />

    <WavyLine :short="true" />

    <div v-if="!buttonOnly">
      <div class="_legends">
        <div class="legend">
          <span class="_icon"
            ><img
              src="/images/icons/nuts.svg"
              style="width: 12px; height: 12px;"
          /></span>
          <span class="_text">Contains nuts</span>
        </div>
        <div class="legend">
          <span class="_icon"><i class="fas fa-pepper-hot"></i></span>
          <span class="_text">Mild/Spicy</span>
        </div>
        <div class="legend">
          <span class="_icon"><i class="fas fa-leaf"></i></span>
          <span class="_text">Vegetarian</span>
        </div>
      </div>

      <div class="_menuItems">
        <div :key="key" v-for="(category, key) of Object.keys(restaurant.menu)">
          <div class="_categoryItems">
            <h2 class="categoryTitle">{{ category }}</h2>
            <div
              class="_item"
              :key="itemKey"
              v-for="(item, itemKey) of restaurant.menu[category]"
            >
              <div class="_header">
                <div class="_name">
                  <div class="_title">{{ item.name }}</div>
                  <div class="_extras">
                    <span v-if="item.nuts"
                      ><img
                        src="/images/icons/nuts.svg"
                        style="width: 12px; height: 12px;"
                    /></span>
                    <span v-if="item.spicy"
                      ><i class="fas fa-pepper-hot"></i
                    ></span>
                    <span v-if="item.vegeterian"
                      ><i class="fas fa-leaf"></i
                    ></span>
                  </div>
                </div>
                <div>
                  <div v-if="item.price">
                    <div
                      class="_price priceGridSmall"
                      v-if="Array.isArray(item.price)"
                    >
                      <span
                        :key="priceKey"
                        v-for="(price, priceKey) of item.price"
                        >£{{ price.toFixed(1) }}</span
                      >
                    </div>
                    <div class="_price" v-else>
                      £{{ item.price.toFixed(1) }}
                    </div>
                  </div>
                  <div
                    v-else-if="item.priceDescription"
                    class="price-details-grid"
                  >
                    <div
                      class="priceItem"
                      :key="key"
                      v-for="(p, key) of item.priceDescription"
                    >
                      <div class="priceName">{{ p.name }}</div>
                      <div class="priceValue">£{{ p.price.toFixed(1) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="_ingredients">{{ item.ingredients }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; justify-content: center;" v-else>
      <a :href="restaurant.path" target="_blank" class="btn large filled"
        >Open Menu</a
      >
    </div>

    <WavyLine />

    <div v-if="showOrderUI" class="_orderOnlineGroup">
      <div class="_orderOnline">
        <SectionTitle subtitle="Delivery" title="Order Online" />
        <p class="_text">Order & Enjoy Our Menu at home</p>

        <WavyLine :short="true" />

        <div class="_cta">
          <a :href="restaurant.deliveroo" target="_blank" class="btn filled"
            >Deliveroo</a
          >
          <span class="_or">or</span>
          <a :href="restaurant.uberEats" target="_blank" class="btn filled"
            >UberEats</a
          >
        </div>
      </div>

      <WavyLine />
    </div>
  </div>
</template>

<script>
export default {
  props: ["restaurant", "showOrderOnline", "buttonOnly"],
  data() {
    return {
      showOrderUI: this.showOrderOnline != null ? this.showOrderOnline : true,
    };
  },
};
</script>

<style lang="scss" scoped>
._menuItems {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 50px;
  column-gap: 50px;
}

@media screen and (max-width: 750px) {
  ._menuItems {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

._legends {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;

  .legend {
    display: flex;
    align-items: center;
    margin: 0 30px;
    ._icon {
      font-size: 14px;
    }
    ._text {
      margin-left: 20px;
      color: $greyText;
    }
  }
}

.categoryTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  text-transform: uppercase;
  color: $greyText;
  color: #800000;
}

._categoryItems {
  width: 100%;
  display: grid;
  row-gap: 30px;
  ._item {
    ._header {
      display: flex;
      justify-content: space-between;
      ._name {
        font-weight: bold;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height */
        text-transform: uppercase;
        display: flex;
        align-items: center;
        ._title {
          margin-right: 10px;
        }
        ._extras {
          display: grid;
          grid-template-columns: 20px 20px 20px;
          font-size: 12px;
        }
      }
      ._price {
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        text-align: center;
        text-transform: uppercase;
        &.priceGridSmall {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: 20px;
          align-items: center;
          font-size: 14px;
        }
      }
    }
    ._ingredients {
      font-size: 13px;
      line-height: 19px;
      /* identical to box height */
      text-transform: lowercase;
      color: rgba(90, 90, 90, 0.5);
    }
  }
}

._orderOnlineGroup {
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

._orderOnline {
  display: grid;
  row-gap: 25px;
  padding: 50px 0;
  ._text {
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    /* identical to box height */
    text-align: center;
    text-transform: capitalize;
    color: $greyText;
  }
  ._cta {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    column-gap: 25px;
    max-width: 420px;
    width: 100%;
    align-items: center;
    margin: 0 auto;
    .btn {
      height: 60px;
      padding: 0 50px;
      font-size: 18px;
    }
    ._or {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */
      text-align: center;
      color: $greyText;
    }
  }
}

.price-details-grid {
  display: flex;
  gap: 20px;
  .priceItem {
    display: grid;
    row-gap: 3px;
    justify-items: center;
    justify-content: center;
  }
  .priceValue {
    font-size: 14px;
  }
  .priceName {
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    opacity: 0.8;
  }
}
</style>
