<template>
  <div class="home">
    <Hero class="_hero">
      <div class="ovh mg-b-30">
        <h1 class="_title" data-aos="fade-up" data-aos-delay="800">
          The Cedar Restaurant
        </h1>
      </div>
      <div class="ovh">
        <p class="_description" data-aos="fade-up" data-aos-delay="1000">
          Welcome to The Cedar restaurants in London. Our award winning eatery
          offers a wide range of delicious and authentic Lebanese cuisine for
          eat-in & delivery.
        </p>
      </div>
    </Hero>

    <div class="_homeContainer">
      <div class="_chooseRestaurant">
        <div class="wrapper">
          <SectionTitle
            class="titleMG"
            subtitle="the cedar restaurant"
            title="Choose location"
          />
          <div class="_restaurantList" id="restaurantListStatic">
            <div
              class="_restaurant"
              :id="`RestaurantID-${key}`"
              :key="key"
              v-for="(restaurant, key) of restaurants"
              :class="{ active: key == selectedRestaurant }"
              @click="switchRestaurant(key)"
            >
              <span
                >{{ restaurant.name
                }}{{ restaurant.nowOpen ? " • Now Open" : "" }}</span
              >
              <span class="dot"></span>
            </div>
          </div>
        </div>

        <WavyLine />

        <div class="wrapper">
          <div id="RestaurantMenuContainer">
            <div v-if="selectedRestaurant != null">
              <div v-if="!restaurants[selectedRestaurant].comingSoon">
                <div>
                  <Menu
                    :restaurant="restaurants[selectedRestaurant]"
                    :buttonOnly="true"
                  />
                </div>

                <div>
                  <Contact :restaurant="restaurants[selectedRestaurant]" />
                </div>
              </div>
              <div class="comingSoonMessage" v-else>
                <h1 class="_title">Coming Soon</h1>
                <WavyLine :short="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Hero from "../components/home/Hero.vue";
import Menu from "../components/home/Menu.vue";
import Contact from "../components/home/Contact.vue";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

import { restaurants as listOfRestaurants } from "../menu";

export default {
  components: {
    Hero,
    Menu,
    Contact,
  },
  title:
    "The Cedar Restaurant | Home | Award Winning Lebanese Cuisine | London",
  data() {
    return {
      selectedRestaurant: null,
      restaurants: listOfRestaurants,
      gsapAni: null,
    };
  },
  methods: {
    switchRestaurant(index) {
      //   window.open(this.restaurants[index].path, "_blank").focus();

      this.selectedRestaurant = index;
      gsap.to(window, 0.3, {
        scrollTo: { y: `#RestaurantMenuContainer`, offsetY: 50 },
      });
    },
    addAnimatatedMenu() {
      if (this.gsapAni) this.gsapAni.progress(1).kill();
      this.gsapAni = null;

      this.gsapAni = gsap.timeline({
        scrollTrigger: {
          trigger: "#restaurantListStatic",
          start: "top top",
          end: "+=100000px",
          onEnter() {
            document
              .querySelector("#restaurantListFixed")
              .classList.add("active");
          },
          onLeave() {
            document
              .querySelector("#restaurantListFixed")
              .classList.remove("active");
          },
          onEnterBack() {
            document
              .querySelector("#restaurantListFixed")
              .classList.add("active");
          },
          onLeaveBack() {
            document
              .querySelector("#restaurantListFixed")
              .classList.remove("active");
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._homeContainer {
  padding-top: 200px;
}

.titleMG {
  margin-bottom: 30px;
}

.comingSoonMessage {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ._title {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 50px;
    text-align: center;
  }
}

._restaurantList {
  z-index: 4;
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  &.fixedPos {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    &.active {
      transform: translateY(0);
    }
    ._restaurant {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      .dot {
        top: auto;
        left: 0;
        bottom: 0;
        transform: translate(0, 0);
        width: 100%;
        border-radius: 0;
      }
      &.active {
        .dot {
          opacity: 1;
          transform: translate(0, 0);
        }
      }
    }
  }

  ._itemListScrollable {
    width: auto;
    height: 130px;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    align-items: center;
  }

  ._restaurant {
    margin: 0;
    position: relative;
    cursor: pointer;
    text-align: center;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .dot {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 5px;
      height: 5px;
      background: $buttonBG;
      border-radius: 50%;
      transform: translate(-50%, 10px);
      opacity: 0;
      transition: ease 0.5s;
    }
    &.active {
      font-weight: bold;
      .dot {
        opacity: 1;
        transform: translate(-50%, -10px);
      }
    }
  }
}

._hero {
  ._title {
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 118%;
    /* identical to box height, or 57px */
    text-align: center;
    text-transform: capitalize;
    color: #fff;
  }
  ._description {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.9);
    max-width: 420px;
  }
}

@media screen and (max-width: 750px) {
  ._restaurantList {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>
