<template>
  <nav>
    <div class="wrapper">
      <div class="_brand">
        <router-link to="/"><img src="/logo.png" class="_logo"/></router-link>
      </div>

      <ul
        class="_links"
        :class="{ shown: mobileNavShown }"
        v-click-outside="hideMobileMenu"
      >
        <li>
          <router-link
            to="/"
            @click="
              scrollTo();
              hideMobileMenu();
            "
            >Menu & Restaurants</router-link
          >
        </li>
        <!-- <li><router-link to="/deli" @click="hideMobileMenu">The Deli</router-link></li> -->
        <li>
          <router-link to="/catering" @click="hideMobileMenu"
            >Catering</router-link
          >
        </li>
        <button class="mobileNavCloseBtn" @click="switchMobileNav">
          <i class="fas fa-times"></i>
        </button>
      </ul>

      <button class="mobileNavBtn" @click="switchMobileNav" ref="mobileMenuBtn">
        <i class="fas fa-bars"></i>
      </button>
    </div>
  </nav>
</template>

<script>
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default {
  data() {
    return {
      mobileNavShown: false,
    };
  },
  methods: {
    scrollTo() {
      gsap.to(window, {
        duration: 0.3,
        scrollTo: {
          y: "#RestaurantMenuContainer",
          offsetY: 100,
        },
      });
    },
    switchMobileNav() {
      this.mobileNavShown = !this.mobileNavShown;
    },
    hideMobileMenu(e) {
      if (!e) return (this.mobileNavShown = false);

      let target = e.target;
      let mobileMenuBtn = this.$refs.mobileMenuBtn;

      if (target != mobileMenuBtn && !mobileMenuBtn.contains(target))
        this.mobileNavShown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobileNavBtn {
  width: 50px;
  height: 50px;
  background: transparent;
  border: 0;
  color: $secondaryColor;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.mobileNavCloseBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: transparent;
  border: 0;
  color: $secondaryColor;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  * {
    transition: ease 0.3s;
  }
  .wrapper {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.fixed {
    position: fixed;
    background: #fff;
    ._logo {
      width: 60px !important;
    }
    ._links {
      a {
        color: $secondaryColor;
      }
    }
  }
}

._brand {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ._logo {
    width: 100px;
  }
}

._links {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  li {
    display: inline;
    margin-left: 50px;
    a {
      text-decoration: none;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      text-transform: capitalize;
      color: $secondaryColor;
      text-transform: uppercase;
      color: #fff;
    }
  }
}

@media screen and (max-width: 750px) {
  .mobileNavBtn {
    display: block;
  }

  .mobileNavCloseBtn {
    display: block;
  }

  ._links {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 450px;
    background: #fff;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 100px 0 50px 0;
    transform: translateX(100%);
    transition: ease 0.5s;
    &.shown {
      transform: translateX(0);
    }
    li {
      margin: 0;
      width: 100%;
      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: 100%;
        height: 50px;
        padding: 0 30px;
        font-size: 20px;
        color: $secondaryColor;
      }
    }
  }
}
</style>
